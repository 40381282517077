// tasks
export const WRP_AANVRAAG = "vervolledigAanvraag";
export const WRP_ADVIES_CONTROLE = "controleerAdvies";
export const WRP_TECHNISCHE_CONTROLE = "technischeControle";
export const WRP_FINANCIELE_CONTROLE = "financieleControle";

export const WRP_TERMIJN_BIJKOMENDE_STUKKEN =
  "termijnBijkomendeStukkenVerstreken"; //"Termijn bijkomende stukken verstreken"
export const WRP_BIJKOMENDE_STUKKEN_BINNENHALEN = "haalBijkomendeStukkenBinnen"; //"Haal bijkomende stukken binnen"
export const WRP_BEVESTIG_WEIGERINGSGRONDEN = "bevestigWeigeringsgronden"; //"Bevestig weigeringsgronden"
export const WRP_STEL_WEIGERINGSBRIEF_OP = "kijkWeigeringsbrievenNa"; //"Stel weigeringsbrief op"
export const WRP_STEP_BESLISSINGSBRIEF_OP = "kijkBelissingsbrievenNa"; //"Stel beslissingsbrief op"

//taken beroep
export const WRP_BEROEPSCONTROLE = "bekijkBeroep";
