import skryv, {form, navigation, search, theme, translation} from "@skryv/core-ng1";

import {selectTasklistCount} from "@skryv/core-ng1/core/store/selectors/tasks";

import wrpCustomizations from "./customizations";
import logo from "./theme/assets/images/logo.png";
import nl_BE from "./translations/nl_BE.po";

import {
  unassignedTasksDefinitionKeys
} from "./customizations/components/dashboard/UnassignedTasksDashboard/UnassignedTasksDashboard";
import {
  appealDefinitionKeys
} from "./customizations/components/dashboard/UnassignedAppealsDashboard/UnassignedAppealsDashboard";
import {SearchAllType} from "@skryv/search-client";

const skrPages = skryv.constants.navigation.pageNames;
// const skrSearchTables = skryv.constants.search.searchNames;

theme.logo(logo, "Mijn Verbouwpremie");

translation.add("nl_BE", nl_BE).locale("nl_BE");

form
  .addCustomComponentName(
    "wrpFactuurcontroleWizard",
    "wrpFactuurcontroleWizard"
  )
  .addCustomComponentName("wrpCategorySelector", "wrpCategorySelector")
  .addCustomComponentName(
    "wrpFactuurcontroleOverview",
    "wrpFactuurcontroleOverview"
  );

navigation
  .page(skrPages.DASHBOARD, {
    extend: true,
    views: {
      actions: "wrpDashboardActions",
      content: "wrpMyTasksDashboard",
    },
    resolve: { initSubmenuBadges: initDashboardSubmenuBadges() },
  })
  .page("dashboardUnassignedTasks", {
    extend: skrPages.DASHBOARD,
    url: "/dashboard/unassigned-tasks",
    views: {
      content: "wrpUnassignedTasksDashboard",
    },
    resolve: { initSubmenuBadges: initDashboardSubmenuBadges() },
  })
  .page("dashboardAppeal", {
    extend: skrPages.DASHBOARD,
    url: "/dashboard/appeal",
    views: {
      content: "wrpUnassignedAppealsDashboard",
    },
    resolve: { initSubmenuBadges: initDashboardSubmenuBadges() },
  })
  .page(skrPages.TASK, {
    extend: true,
    views: {
      summary: "wrpTaskSummary",
    },
  })
  .page(skrPages.SUBTASK, {
    extend: true,
    views: {
      summary: "wrpTaskSummary",
    },
  })
  .page(skrPages.DOSSIER, {
    extend: true,
    views: {
      content: "dossierOverview",
    },
  })
  .page("searchTasksCustom", {
    extend: skrPages.SEARCH_TASKS,
    views: {
      content: "wrpTasksSearch",
    },
  })
  .page(skrPages.SEARCH_HISTORIC_TASKS, {
    extend: true,
    views: {
      content: "wrpHistoricTasksSearch",
    },
  })
  .page("dashboardVersions", {
    extend: skrPages.DASHBOARD,
    url: "/versions",
    views: {
      content: "wrpVersionsDashboard",
    },
  })
  .tab("dashboard", "dashboardUnassignedTasks", {
    label: "Te verdelen taken",
    state: "dashboardUnassignedTasks",
    stateOpts: { reload: true },
    badgeQuery: (state) => {
      return selectTasklistCount(state, "dashboardUnassignedTasks");
    },
  })
  .tab("dashboard", "dashboardAppeal", {
    label: "Te verdelen beroepen",
    state: "dashboardAppeal",
    stateOpts: { reload: true },
    badgeQuery: (state) => {
      return selectTasklistCount(state, "dashboardAppeal");
    },
  })
  .tab("dashboard", "my_tasks", {
    label: "Mijn open taken",
    extend: true,
  })
  .tab("dashboard", "dossiers", {
    label: "Dossiers",
    extend: true,
    badgeQuery: () => false, // turn this off since the number will only go up
  })
  .tab("search", "tasksCustom", {
    label: "Open taken",
    state: "searchTasksCustom",
    stateOpts: { reload: true },
  })
  .tab("search", "tasks", {
    label: "Open taken (filterbaar)",
    extend: true,
    authorizationKey: "SEARCH_OPEN_TASKS",
  })
  .setTabsForGroup("search", [
    "dossiers",
    "tasksCustom",
    "tasks",
    "historicTasks",
    "documents",
  ]);

search.defaultForTasks({
  name: "Default view",
  searchTerm: "_exists_:task AND dossier.label:2?-MVP-*",
  searchTermType: SearchAllType.ESQL,
  columns: [
    "task.name",
    "dossier.label",
    "task.due",
    "task.assigneeUser.username",
    "task.created",
  ],
  filters: [],
  sort: {
    criterion: "task.created",
    desc: true,
  },
});

skryv
  .customize(wrpCustomizations)
  .customize(navigation)
  .customize(theme)
  .customize(translation)
  .customize(form)
  .customize(search)
  .initialize(document.getElementById("root"));

function initDashboardSubmenuBadges() {
  return ($ngRedux, userService) => {
    "ngInject";
    // this function is called upon initialisation of the submenu;
    // it makes sure that all required data is fetched so the numbers for the submenu badges can be selected from the state
    return () => {
      $ngRedux.dispatch({
        type: "FETCH_TASK_FILTER_COUNT",
        listKey: "tasks_mine",
        api: {
          params: {
            assignee: userService.getCurrentUserSub(),
          },
          url: "/api/tasks/filtered/count",
          method: "GET",
        },
      });
      $ngRedux.dispatch({
        type: "FETCH_TASK_FILTER_COUNT",
        listKey: "dashboardUnassignedTasks",
        api: {
          params: {
            isAssigned: false,
            taskDefinitionKey: unassignedTasksDefinitionKeys.join(","),
          },
          url: "/api/tasks/filtered/count",
          method: "GET",
        },
      });
      $ngRedux.dispatch({
        type: "FETCH_TASK_FILTER_COUNT",
        listKey: "dashboardAppeal",
        api: {
          params: {
            isAssigned: false,
            taskDefinitionKey: appealDefinitionKeys.join(","),
          },
          url: "/api/tasks/filtered/count",
          method: "GET",
        },
      });
    };
  };
}
