import angular from "angular";
import get from "lodash/get";

import { formatAmount } from "../../premiumCalculationHelpers";

import template from "./FactuurcontroleSummaryExtended.html";
import "./FactuurcontroleSummaryExtended.scss";

const namespace = "wrp/components/factuur/FactuurcontroleSummaryExtended";

angular.module(namespace, []).component("wrpFactuurcontroleSummaryExtended", {
  bindings: {
    circleInfo: "<",
    algemeneInfo: "<",
    showCalculations: "<",
  },
  template,
  controller: function () {
    "ngInject";

    this.showCalculations = false;
    this.formatAmount = formatAmount;

    this.$onChanges = function (changes) {
      if (changes.isShown) this.showCalculations = false;
    };

    this.doelgroep = () => {
      const d = get(this.algemeneInfo, "doelgroep");
      switch (d) {
        case "doelgroep1":
          return "Doelgroep 1";
        case "doelgroep2":
          return "Doelgroep 2";
        case "doelgroep3":
          return "Doelgroep 3";
        default:
          return "-";
      }
    };
  },
});

export { template };
export default namespace;
