import angular from "angular";

import { formatAmount } from "../../premiumCalculationHelpers";

import template from "./PremieBerekening.html";
import "./PremieBerekening.scss";

const namespace = "wrp/components/factuur/PremieBerekening";

angular.module(namespace, []).component("wrpPremieBerekening", {
  bindings: {
    circleInfo: "<",
    algemeneInfo: "<",
  },
  template,
  controller: function () {
    "ngInject";

    this.formatAmount = formatAmount;

    this.circleInfoForCategory = () => {
      if (!this.circleInfo) return;

      return this.circleInfo.filter(
        (c) => !["totaal_gevalideerd", "totaal_premie"].includes(c.key)
      );
    };
  },
});

export { template };
export default namespace;
