import angular from "angular";
import get from "lodash/get";

import iconsForCategory from "@skryv/bundle/customizations/constants/iconsForCategories";

import template from "./CategorySelector.html";
import "./CategorySelector.scss";

const namespace = "wvl/components/factuur/CategorySelector";

// names of the computed expressions used > this changes when you change the names/keys of the computed expressions
const CE_REQUESTED_CATEGORIES_OK = "aangevraagde_categorieen_technisch_ok";

angular
  .module(namespace, ["ui.bootstrap"])
  .factory("wrpCategorySelector", function () {
    "ngInject";

    return {
      template: template,
      link: linkCategorySelector,
    };

    function linkCategorySelector(scope, input, manipulator) {
      scope.categoryIcons = iconsForCategory;

      scope.hadSelectedOptions = function (options, optionManipulators) {
        for (let i = 0; i < options.length; i++) {
          if (optionManipulators[options[i].name].isSelected) return true;
        }
        return false;
      };

      scope.isDisabledOption = function (categoryName) {
        return !manipulator.optionManipulators[categoryName].isActive();
      };

      scope.isTechnicallyApprovedCategory = (categoryName) => {
        let technicallyApprovedCategories =
          manipulator.computedExpressions[CE_REQUESTED_CATEGORIES_OK];
        return get(technicallyApprovedCategories, categoryName);
      };

      scope.lookupLabel = (selectedOption) =>
        get(input, [
          "description",
          "optionDescriptions",
          selectedOption,
          "label",
        ]);
    }
  });

export default namespace;
