import angular from "angular";

import { taskTypes } from "@skryv/core-ng1/core/constants/taskTypes";
import { searchNames } from "@skryv/core-ng1/config/search";
import { parseFieldDefinition } from "@skryv/search-client";

import template from "./UnassignedAppealsDashboard.html";

import { customFetchTasklistAction } from "../../../store/actions";
import * as taskDefinitionKeys from "../../../constants/taskDefinitionKeys";

const namespace =
  "@skryv/core-ng1/components/dashboard/UnassignedAppealsDashboard";

export const appealDefinitionKeys = [taskDefinitionKeys.WRP_BEROEPSCONTROLE];

const UNASSIGNED_APPEALS_DASHBOARD_SESSION_STORAGE_KEY = "unassignedAppealsDashboard";

angular.module(namespace, []).component("wrpUnassignedAppealsDashboard", {
  template,
  controller: function ($ngRedux, searchOptions) {
    "ngInject";
    const $ctrl = this;

    this.$onInit = function () {
      $ctrl.searchPageName = UNASSIGNED_APPEALS_DASHBOARD_SESSION_STORAGE_KEY;
    }

    this.columnDefinitions = [
      {
        heading: "Dossier",
        select: ["dossier.label", "dossier.id"],
        field: "dossier.label",
        sort: "dossier.label",
        state: () => "dossier",
        stateParams: (cell, searchHit) => {
          return {
            dossierId: parseFieldDefinition("dossier.id").extract(searchHit)[0],
          };
        },
      },
      {
        heading: "Task",
        field: "task.name",
        sort: "task.name",
        state: (cell, searchHit) => {
          if (searchHit.task.type === taskTypes.POP_UP) return "dossier";
          else return "task";
        },
        stateParams: (cell, searchHit) => {
          if (searchHit.task.type === taskTypes.POP_UP) {
            return {
              dossierId:
                parseFieldDefinition("dossier.id").extract(searchHit)[0],
            };
          }
          return {
            taskId: parseFieldDefinition("task.id").extract(searchHit)[0],
          };
        },
      },
      {
        heading: "Wijs toe",
        key: "assign",
        field: "task",
        sort: false,
        type: "search/user-editable",
      },
      {
        heading: "Opstartdatum taak",
        field: "task.created",
        type: "search/date",
        format: "dd/MM/yyyy",
      },
      {
        heading: "Aanvraagdatum",
        field: "task.aanvraagdatum",
        key: "task.aanvraagdatum",
        sort: "task.aanvraagdatum",
        type: "search/date",
        format: "dd/MM/yyyy",
      },
      {
        heading: "Beroepsdatum",
        field: "task.beroepsdatum",
        key: "task.beroepsdatum",
        sort: "task.beroepsdatum",
        type: "search/date",
        format: "dd/MM/yyyy",
      },
      {
        heading: "Beslissingsdatum",
        field: "task.beslissingsdatum",
        key: "task.beslissingsdatum",
        sort: "task.beslissingsdatum",
        type: "search/date",
        format: "dd/MM/yyyy",
      },
      {
        heading: "Categorieën",
        field: "task.categorieen",
        key: "task.categorieen",
        type: "search/document/multichoice",
        sort: false,
        choices: [
          {
            name: "binnenrenovatie",
            label: "Binnenrenovatie",
          },
          {
            name: "muurrenovatie",
            label: "Buitenmuur",
          },
          {
            name: "dakrenovatie",
            label: "Dak",
          },
          {
            name: "technischeInstallaties",
            label: "Elektriciteit en sanitair",
          },
          {
            name: "gascondensatieketel",
            label: "Gascondensatieketel",
          },
          {
            name: "buitenschrijnwerkGlas",
            label: "Ramen en deuren",
          },
          {
            name: "vloerrenovatie",
            label: "Vloer",
          },
          {
            name: "warmtepomp",
            label: "Warmtepomp",
          },
          {
            name: "warmtepompboiler",
            label: "Warmtepompboiler",
          },
          {
            name: "zonneboiler",
            label: "Zonneboiler",
          },
        ],
      },
      {
        heading: "Uitvoeringsadres",
        field: "task.uitvoeringsadres",
        key: false,
        sort: false,
        type: "search/text",
      },
      {
        heading: "Aanvrager",
        field: "task.aanvrager",
        key: "task.aanvrager",
        sort: "task.aanvrager",
        type: "search/text",
      },
      {
        heading: "Type Aanvrager",
        field: "task.typeAanvrager",
        key: "task.typeAanvrager",
        type: "search/document/choice",
        sort: false,
        choices: [
          {
            name: "eigenaarBewoner",
            label: "Eigenaar-bewoner",
          },
          {
            name: "svkVerhuurder",
            label: "SVK-verhuurder",
          },
          {
            name: "onderneming",
            label: "Onderneming",
          },
          {
            name: "vme",
            label: "VME",
          },
          {
            name: "onbekend",
            label: "Andere",
          },
          {
            name: "huurder",
            label: "Huurder",
          },
          {
            name: "externeInvesteerder",
            label: "Enterne investeerder",
          },
        ],
      },
      {
        heading: "Type Gebouw",
        field: "task.typeGebouw",
        key: "task.typeGebouw",
        sort: false,
        type: "search/document/choice",
        choices: [
          {
            name: "appartement",
            label: "Appartement",
          },
          {
            name: "eengezinswoning",
            label: "Eéngezinswoning",
          },
          {
            name: "appartementsgebouw",
            label: "Appartementsgebouw",
          },
          {
            name: "nietResidentieel",
            label: "Niet residentieel",
          },
        ],
      },
      {
        heading: "Doelgroep",
        field: "task.doelgroep",
        key: "task.doelgroep",
        sort: false,
        type: "search/document/choice",
        choices: [
          {
            name: "doelgroep1",
            label: "Doelgroep 1",
          },
          {
            name: "doelgroep2",
            label: "Doelgroep 2",
          },
          {
            name: "doelgroep3",
            label: "Doelgroep 3",
          },
          {
            name: "dnk",
            label: "Kon geen beslissing nemen",
          },
        ],
      },
      {
        heading: "Type Dossier",
        field: "task.typeDossier",
        key: "task.typeDossier",
        sort: false,
        type: "search/document/choice",
        choices: [
          {
            name: "wvl",
            label: "Wonen-Vlaanderen",
          },
          {
            name: "fluvius",
            label: "Fluvius",
          },
          {
            name: "mix",
            label: "Gemixt",
          },
        ],
      },
      {
        heading: "Toegewezen aan",
        field: "task.toegewezenAan",
        key: "task.toegewezenAan",
        sort: false,
        type: "search/document/choice",
        choices: [
          {
            name: "wvl",
            label: "Wonen-Vlaanderen",
          },
          {
            name: "fluvius",
            label: "Fluvius",
          },
          {
            name: "onbekend",
            label: "Onbekend",
          },
        ],
      },
      {
        heading: "Behandelaar Administratieve Controle",
        field: "task.administratieveDossierbehandelaar",
        key: "task.administratieveDossierbehandelaar",
        sort: "task.administratieveDossierbehandelaar",
        type: "search/text",
      },
      {
        heading: "Behandelaar Technische Controle",
        field: "task.technischeDossierbehandelaar",
        key: "task.technischeDossierbehandelaar",
        sort: "task.technischeDossierbehandelaar",
        type: "search/text",
      },
      {
        heading: "Reden van beroep",
        field: "task.redenVanBeroep",
        key: "task.redenVanBeroep",
        type: "search/document/multichoice",
        sort: false,
        choices: [
          {
            name: "bepalingDoelgroepInkomenscategorie",
            label: "Bepaling doelgroep",
          },
          {
            name: "categorieenVanWerken",
            label: "Categoriën van werken",
          },
          {
            name: "andere",
            label: "Andere",
          },
        ],
      },
      {
        heading: "Behandeling",
        key: "task.behandeling",
        sort: false,
        type: "search/document/choice",
        field: "task.behandeling",
        choices: [
          {
            name: "parent",
            label: "Parent",
          },
          {
            name: "child",
            label: "Child",
          },
          {
            name: "standaard",
            label: "Standaard",
          },
        ],
      },
    ];

    this.views = [
      {
        name: "Default view",
        searchTerm: "",
        columns: [
          "dossier.label",
          "task.name",
          "assign",
          "task.created",
          "task.toegewezenAan",
          "task.beroepsdatum",
          "task.aanvraagdatum",
          //"task.beslissingsdatum",
          "task.categorieen",
          "task.uitvoeringsadres",
          //"task.aanvrager",
          //"task.typeAanvrager",
          //"task.typeGebouw",
          "task.doelgroep",
          //"task.typeDossier",
          "task.administratieveDossierbehandelaar",
          "task.technischeDossierbehandelaar",
          //"task.behandeling",
        ],
        filters: [],
        sort: {
          criterion: "task.beroepsdatum",
          desc: false,
        },
      },
    ];

    this.searchDefinition = {
      key: "dashboard/tasks/v2",
      label: "tasks",
      datasourceType: "be/tasks",
      columnDefinitions: this.columnDefinitions,
      views: this.views,
      allowExport: true,
      allowSearch: false,
      allowViewConfiguration: true,
      allowViewSelection: true,
      searchOptions: searchOptions.getSearchOptionsConfigurationForKey(
        searchNames.DASHBOARD_MY_TASKS
      ),
      hiddenFilters: [
        {
          field: parseFieldDefinition("task.isAssigned"),
          value: false,
        },
        {
          field: parseFieldDefinition("task.taskDefinitionKey"),
          value: appealDefinitionKeys,
        },
        {
          field: parseFieldDefinition("task.includeComments"),
          value: false,
        },
      ],
      endpoint: (key) => (query, pageNumber, paginationSize) =>
        $ngRedux.dispatch(
          customFetchTasklistAction(key, query, pageNumber, paginationSize)
        ),
    };
  },
});

export { template };
export default namespace;
