export function customFetchTasklistAction(
  listKey = "DEFAULT",
  requestPayload = {},
  pageNumber,
  pageSize
) {
  let params = Object.assign({ page: pageNumber - 1, size: pageSize });
  if (requestPayload.sorting) {
    params.sort =
      requestPayload.sorting[0].sortBy +
      "," +
      requestPayload.sorting[0].sortOrder;
    delete requestPayload.sorting;
  }
  return {
    type: "FETCH_TASKS",
    listKey: listKey,
    api: {
      params,
      url: "/api/extendedtasks/filtered",
      method: "POST",
      data: requestPayload,
    },
  };
}

export function fetchDossierTypeVersions() {
  return {
    type: "FETCH_DOSSIER_TYPES",
    listKey: "DASHBOARD",
    api: {
      url: "/api/wrp/config/versions",
      method: "GET",
    },
  };
}

export function fetchDossierIsInIncident(dossierId) {
  return {
    type: "FETCH_DOSSIER_IS_IN_INCIDENT",
    api: {
      url: `/api/incidents/isIncidentDossier?businessKey=${dossierId}`,
      method: "GET",
    },
  };
}

export function fetchProcessesWithIncidentsForDossier(dossierId) {
  return {
    type: "FETCH_PROCESSES_WITH_INCIDENTS_FOR_DOSSIER",
    api: {
      url: `/api/incidents/getProcessesWithIncidentsForDossier?businessKey=${dossierId}`,
      method: "GET",
    },
  };
}

export function fetchUserForRoleInThisDossier(dossierId, role) {
  return {
    type: "FETCH_USERS_FOR_ROLE_IN_THIS_DOSSIER",
    api: {
      url: `/api/dossiers/${dossierId}/users?role=${role}`,
      method: "GET",
    },
  };
}
