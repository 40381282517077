import { Angular1Plugin } from "@skryv/core-ng1";

import components from "./components";

export class WrpCustomizations extends Angular1Plugin {
  constructor() {
    super("wrp", [components]);
  }
}

export default new WrpCustomizations();
