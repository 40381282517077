export default {
  binnenrenovatie: "Binnenrenovatie",
  buitenschrijnwerkGlas: "Ramen en deuren",
  dakrenovatie: "Dak",
  gascondensatieketel: "Gascondensatieketel",
  muurrenovatie: "Buitenmuur",
  technischeInstallaties: "Elektriciteit en sanitair",
  vloerrenovatie: "Vloer",
  warmtepomp: "Warmtepomp",
  warmtepompboiler: "Warmtepompboiler",
  zonneboiler: "Zonneboiler",
};
