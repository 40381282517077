import angular from "angular";

import {cloneDeep, get} from "lodash";

import {taskTypes} from "@skryv/core-ng1/core/constants/taskTypes";
import {searchNames} from "@skryv/core-ng1/config/search";
import { parseFieldDefinition } from "@skryv/search-client";

import {fetchAuthorizedDossiers} from "@skryv/core-ng1/core/store/actions/authorizations";
import {fetchAllDossierDefinitions} from "@skryv/core-ng1/core/store/actions/dosdefs";
import {fetchSearchdefByKey} from "@skryv/core-ng1/core/store/actions/searchdef";
import {selectAuthorizedDossierDefinitions} from "@skryv/core-ng1/core/store/selectors/authorizations";
import {selectDossierDefinitionByKey} from "@skryv/core-ng1/core/store/selectors/dosdefs";

import template from "../../search/Search.html";

import {customFetchTasklistAction} from "../../../store/actions";
import {
  getDossierTypeFromSessionStorage,
  setDossierTypeToSessionStorage
} from "@skryv/core-ng1/components/search/Search/dossierTypeSessionStorage";

const namespace = "@skryv/core-ng1/components/dashboard/MyTasksDashoard";
const MY_TASK_DASHBOARD_SESSION_STORAGE_KEY = "myTaskDashboard";

angular.module(namespace, []).component("wrpMyTasksDashboard", {
  template,
  controller: function (
    $ngRedux,
    searchOptions,
    searchViews,
    $scope,
    userService,
    $window
  ) {
    // prettier-ignore
    "ngInject";

    const $ctrl = this;

    //to rerender page after selection in dropdown
    const safeApply = function () {
      var phase = $scope.$root.$$phase;
      if (phase !== "$apply" && phase !== "$digest") {
        $scope.$apply();
      }
    };

    const disconnect = $ngRedux.connect(mapStateToThis, {
      fetchSearchdefByKey,
      fetchAllDossierDefinitions,
      fetchAuthorizedDossiers,
    })(this);

    function mapStateToThis(state) {
      return {
        dosdefs: selectAuthorizedDossierDefinitions(state),
        dossierDefinitionByKey: (key) =>
          selectDossierDefinitionByKey(state, key),
      };
    }

    this.$onDestroy = () => {
      disconnect();
    };

    // This is the searchDefinition for the "all dossiertypes" and all the dossiertypes except for MVP
    function createDefaultSearchDefinition(dossierType) {
      // define columns
      const taskColumnDefinitions = [
        {
          heading: "Comments that are pinned to the dashboard",
          icon: "skr-icon-note-regular",
          field: "task.annotations",
          sort: false,
          type: "search/dossier/comments",
        },
        {
          heading: "Task",
          field: "task.name",
          sort: "task.name",
          state: (cell, searchHit) => {
            if (searchHit.task.type === taskTypes.POP_UP) return "dossier";
            else return "task";
          },
          stateParams: (cell, searchHit) => {
            if (searchHit.task.type === taskTypes.POP_UP) {
              return {
                dossierId:
                  parseFieldDefinition("dossier.id").extract(searchHit)[0],
              };
            }
            return {
              taskId: parseFieldDefinition("task.id").extract(searchHit)[0],
            };
          },
        },
        {
          heading: "Dossier",
          select: ["dossier.label", "dossier.id"],
          field: "dossier.label",
          sort: "dossier.label",
          state: () => "dossier",
          stateParams: (cell, searchHit) => {
            return {
              dossierId:
                parseFieldDefinition("dossier.id").extract(searchHit)[0],
            };
          },
        },
        {
          heading: "Due date",
          field: "task.due",
          type: "search/date",
          format: "dd/MM/yyyy",
        },
        {
          heading: "Created",
          field: "task.created",
          type: "search/date",
          format: "dd/MM/yyyy",
        },
        {
          heading: "Urgency",
          icon: "skr-icon-warning",
          field: "task.due",
          key: "task.urgency",
          type: "search/task/urgency",
          sort: false,
          filter: false,
          // TODO for this column, sorting and filtering does not work when using paginated backend search so we disable it for now
        },
        {
          heading: "Urgent date",
          field: "task.urgentDate",
          key: "task.urgencyDate",
          type: "search/date",
          format: "dd/MM/yyyy",
          sort: false,
          filter: false,
          // TODO for this column, sorting and filtering does not work when using paginated backend search so we disable it for now
        },
      ];

      return extendSearchDefinition(
        {
          key: "dashboard/tasks/v2",
          label: "tasks",
          datasourceType: "be/tasks",
          columnDefinitions: taskColumnDefinitions,
          views: searchViews.getViewsForKey(searchNames.DASHBOARD_MY_TASKS),
          allowSearch: false,
          allowViewConfiguration: false,
          allowViewSelection: false,
          showDossierTypeFilter: true,
          searchOptions: searchOptions.getSearchOptionsConfigurationForKey(
            searchNames.DASHBOARD_MY_TASKS
          ),
          allowExport: false,
        },
        dossierType
      );
    }

    this.searchDefinition = null;

    this.$onInit = function () {
      Promise.all([
        this.fetchAllDossierDefinitions(),
        this.fetchAuthorizedDossiers(),
      ]).then(() => {
        // Retrieve the last selected dossier type key from session storage
        $ctrl.searchPageName = MY_TASK_DASHBOARD_SESSION_STORAGE_KEY;
        $ctrl.onDossierTypeSelection(getDossierTypeFromSessionStorage(MY_TASK_DASHBOARD_SESSION_STORAGE_KEY));
        $ctrl.showDossierTypeFilter = true;
      });
    };

    this.onDossierTypeSelection = (dossierType) => {
      // Save the last selected dossier type key in the session
      setDossierTypeToSessionStorage(MY_TASK_DASHBOARD_SESSION_STORAGE_KEY, dossierType)

      // If the dossiertype is MVP, get the customised searchDefinition.
      if (dossierType && dossierType === "mijn_verbouwpremie") {
        this.fetchSearchdefByKey("mijn_verbouwpremie_my_tasks_dashboard")
          .then((state) => get(state, ["api", "response", "data", "content"]))
          .then((searchdef) => {
            let searchDefinitionClone = cloneDeep(searchdef);
            // add links to fields dossierlabel and task
            this.searchDefinition = extendSearchDefinition(
              {
                ...searchDefinitionClone,
                columnDefinitions: searchDefinitionClone.columnDefinitions.map(
                  (column) => {
                    if (column.field === "dossier.label") {
                      return {
                        ...column,
                        state: () => "dossier",
                        stateParams: (cell, searchHit) => {
                          const dossierId =
                            parseFieldDefinition("dossier.id").extract(
                              searchHit
                            )[0];
                          return { dossierId };
                        },
                      };
                    }
                    if (column.field === "task.name") {
                      return {
                        ...column,
                        state: (cell, searchHit) => {
                          if (searchHit.task.type === taskTypes.POP_UP)
                            return "dossier";
                          else return "task";
                        },
                        stateParams: (cell, searchHit) => {
                          if (searchHit.task.type === taskTypes.POP_UP) {
                            return {
                              dossierId:
                                parseFieldDefinition("dossier.id").extract(
                                  searchHit
                                )[0],
                            };
                          }
                          return {
                            taskId:
                              parseFieldDefinition("task.id").extract(
                                searchHit
                              )[0],
                          };
                        },
                      };
                    } else return column;
                  }
                ),
              },
              dossierType
            );
            safeApply();
          });
        return;
      }

      // in all other cases (dossiertype is not MVP) return defaultSearchDefinition
      this.searchDefinition = createDefaultSearchDefinition(dossierType);
      safeApply();
    };

    function extendSearchDefinition(searchDefinition, dossierType) {
      return {
        ...searchDefinition,
        views: searchDefinition.views.map((view) => ({
          ...view,
          dossierType: dossierType,
        })),
        hiddenFilters: [
          {
            field: parseFieldDefinition("task.assignee"),
            value: userService.getCurrentUserSub(),
          },
          ...(dossierType
            ? [
                {
                  field: "dossierDefinitionKey",
                  value: [dossierType],
                },
              ]
            : []),
        ],
        endpoint: (key) => (query, pageNumber, paginationSize) =>
          $ngRedux.dispatch(
            customFetchTasklistAction(key, query, pageNumber, paginationSize)
          ),
      };
    }
  },
});

export { template };
export default namespace;
