export default {
  AANVRAAG: "aanvraag",
  AUTHENTIEKE_DATA: "authentieke_data",
  ADVIES: "automatisch_advies",
  CAT_STATUS: "CategorieenStatus",
  TECHNISCHE_CONTROLE: "technische_controle",
  FINANCIELE_CONTROLE: "factuurcontrole",
  ADM_KWALITEITSCONTROLE: "administratieve_kwaliteitscontrole",
  BESL_KWALITEITSCONTROLE: "beslissing_kwaliteitscontrole",
  ATTEST_CONTROLE: "attest_controle",
  ORAFIN_CREDIT: "orafin_credit",
  BEROEP: "beroep",
  ORAFIN_DEBIT: "orafin_debit",
};
