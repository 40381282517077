import angular from "angular";

import template from "./FactuurcontroleSummaryCompact.html";
import "./FactuurcontroleSummaryCompact.scss";

const namespace = "wrp/components/factuur/FactuurcontroleSummaryCompact";

angular.module(namespace, []).component("wrpFactuurcontroleSummaryCompact", {
  template,
  bindings: {
    circleInfo: "<",
  },
});

export { template };
export default namespace;
