import angular from "angular";

import * as dossierKeys from "@skryv/bundle/customizations/constants/dossierKeys";

import template from "./DossierOverview.html";

const namespace = "components/dossier/dossierOverview";

angular.module(namespace, []).component("dossierOverview", {
  template,
  bindings: {
    apiDossier: "<",
    dosdef: "<",
    procdefs: "<",
    processes: "<",
    authorizations: "<",
  },
  controller: function () {
    this.dossierKeys = dossierKeys;
  },
}).name;

export default namespace;
