export function mockFetchAllDossierDefinitions(mockAuthenticatedRequest) {
    mockAuthenticatedRequest.defineResponse(
        {
            url: "api/dossier-definitions",
            method: "GET",
            cache: true,
        },
        [
            {
                createdAt: "2023-12-11T15:04:57.000+00:00",
                updatedAt: "2024-01-22T14:28:48.000+00:00",
                id: "27edc59f-7e80-46ab-895f-47b225ef651f",
                version: 1,
                key: "mijn_verbouwpremie",
                label: "Mijn VerbouwPremie",
                data: {
                    label: "Mijn VerbouwPremie",
                    name: "mijn_verbouwpremie",
                    description: "",
                    category: "",
                    startButtonLabel: "Start nieuwe aanvraag",
                    hasCustomDossierInfoPage: false,
                    processesOnStartup: ["fo_aanvraag"],
                    canBeDeactivatedWhenReady: false,
                    labelProviderConfiguration: {
                        type: "counter",
                        counterLabel: "MVP",
                        includeYearPrefix: true,
                        counterDigits: 6,
                    },
                    creationModes: [
                        {
                            label: "Aanvraag via eLoket",
                            key: "eLoket",
                            processMessagesOnStartup: ["eloket-premie-aanvraag"],
                            labelProviderConfiguration: {
                                type: "counter",
                                counterLabel: "CONCEPT",
                                includeYearPrefix: true,
                                counterDigits: 7,
                            },
                            grantRole: "aanvrager",
                        },
                    ],
                    shouldShowGDPRCheckbox: false,
                    dossierListColumns: "mijn_verbouwpremie_dossier_search",
                },
            },
            {
                createdAt: "2023-12-11T15:04:57.000+00:00",
                updatedAt: "2023-12-11T15:04:57.000+00:00",
                id: "7ab06e00-6d29-47dd-a89a-5859e1e380fa",
                version: 1,
                key: "epc_labelPremie",
                label: "EPC-Labelpremie",
                data: {
                    label: "EPC-Labelpremie",
                    name: "epc_labelPremie",
                    description: "",
                    category: "",
                    startButtonLabel: "Start nieuwe aanvraag",
                    hasCustomDossierInfoPage: false,
                    processesOnStartup: ["fo_aanvraag_epclp"],
                    canBeDeactivatedWhenReady: false,
                    labelProviderConfiguration: {
                        type: "counter",
                        counterLabel: "EPC",
                        includeYearPrefix: true,
                        counterDigits: 6,
                    },
                    creationModes: [
                        {
                            label: "Aanvraag via eLoket",
                            key: "eLoket",
                            processMessagesOnStartup: ["eloket-premie-aanvraag-epc"],
                            labelProviderConfiguration: {
                                type: "counter",
                                counterLabel: "CONCEPT-EPC",
                                includeYearPrefix: true,
                                counterDigits: 7,
                            },
                            grantRole: "aanvrager",
                        },
                    ],
                    shouldShowGDPRCheckbox: false,
                },
            },
            {
                createdAt: "2023-12-11T15:04:57.000+00:00",
                updatedAt: "2023-12-11T15:04:57.000+00:00",
                id: "bdf1a407-1381-4b44-ac76-1173357ab474",
                version: 1,
                key: "bulk_valideer_beslissing_kwaliteitscontrole_epc",
                label: "Bulk validatie beslissing",
                data: {
                    label: "Bulk validatie beslissing",
                    name: "bulk_valideer_beslissing_kwaliteitscontrole_epc",
                    category: "",
                    hasCustomDossierInfoPage: false,
                    processesOnStartup: ["bulk_valideer_beslissing_epc"],
                    canBeDeactivatedWhenReady: true,
                    shouldShowGDPRCheckbox: false,
                },
            },
        ]
    );
}

export function mockFetchAuthorizedDossiers(mockAuthenticatedRequest) {
    mockAuthenticatedRequest.defineResponse(
        {
            url: "api/authorizations/dossiers",
            method: "GET",
            cache: true,
        },
        {
            accessToAllDossiers: true,
        }
    );
}
