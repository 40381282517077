import angular from 'angular';
import immutable from 'seamless-immutable';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

import { fetchAllDossierDefinitions } from '@skryv/core-ng1/core/store/actions/dosdefs';
import { fetchDocumentDefinitionByKey } from '@skryv/core-ng1/core/store/actions/docdefs';
import { createDossier } from '@skryv/core-ng1/core/store/actions/dossiers';
import { selectAllDossierDefinitions } from '@skryv/core-ng1/core/store/selectors/dosdefs';

import template from './WrpDossierNew.html';
import './WrpDossierNew.scss';

const namespace = 'wrp/customizations/dossier/WrpDossierNew';

angular
  .module(namespace, [])
  .component('wrpDossierNew', {
    require: 'ngDialog',
    template,
    controller: function ($ngRedux, $state, $element, $timeout, notifications, docmodCompiler) {
      'ngInject';

      const $ctrl = this;
      $ctrl.dialog = $element.controller('ngDialogController');
      this.dossier = {};
      this.isAddingDossier = false;
      let unsubscribeDocmod = () => {};
      const disconnect = $ngRedux.connect(mapStateToThis, { fetchAllDossierDefinitions, fetchDocumentDefinitionByKey, createDossier })(this);

      function mapStateToThis(state) {
        const types = immutable.asMutable(selectAllDossierDefinitions(state), { deep: true });
        const type = types.filter((type) => type.key === "mijn_verbouwpremie")[0];

        $ctrl.dossier.dossierDefinition = type;

        return {
          types: sortBy(immutable.asMutable(selectAllDossierDefinitions(state), { deep: true }), 'label')
        };
      }

      this.$onInit = () => {
        this.fetchAllDossierDefinitions().then(() => {
          // fetch the document definition if it's defined
          const definitionKey = get(this.dossier, 'dossierDefinition.data.dossierCreationDefinitionKey');
          this.fetchDocumentDefinitionByKey(definitionKey)
            .then(({ api }) => {
              const definition = api.response.data;
              this.hasDocumentDefinition = !isUndefined(definition);
              this.documentModel = docmodCompiler.compilePreview(definition);

              unsubscribeDocmod = this.documentModel.model.listen(() => {
                this.dossierCreationData = this.documentModel.document.document.value;
              });
            });
        });
      };

      this.$onDestroy = () => {
        disconnect();
        unsubscribeDocmod();
      };

      this.hasDocumentErrors = () => {
        return this.documentModel && !isEmpty(this.documentModel.model.errors);
      };

      this.submit = function () {
        if (!this.dossier || !this.dossier.dossierDefinition) return;

        this.isAddingDossier = true;

        this.createDossier(this.dossier.name, this.dossier.dossierDefinition.id,
          null, null, this.dossierCreationData)
          .then(({ api }) => {
            const dossierId = get(api, 'response.data.id');

            notifications.info('Dossier created');

            $state.go('dossier', { dossierId })
              .then(() => {
                this.isAddingDossier = false;
                if (this.dialog) {
                  return this.dialog.closeThisDialog();
                }
              });
          }).finally(() => this.isAddingDossier = false);

      };
    }
  });


export { template };
export default namespace;
