import angular from "angular";

import { findKey, get } from "lodash";

import * as taskKeys from "@skryv/bundle/customizations/constants/taskDefinitionKeys";

import template from "./TaskSummary.html";
import "./TaskSummary.scss";

const namespace = "wrp/components/task/TaskSummary";

angular.module(namespace, []).component("wrpTaskSummary", {
  template,
  bindings: {
    task: "<",
    documentModel: "<",
  },
  controller: function () {
    "ngInject";

    this.$onInit = () => {
      this.taskKey = findKey(
        taskKeys,
        (key) => key === get(this.task, "taskDefinitionKey")
      );
    };
  },
});

export { template };
export default namespace;
