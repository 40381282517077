import angular from "angular";
import filter from "lodash/filter";

import iconsForCategory from "@skryv/bundle/customizations/constants/iconsForCategories";

import template from "./FactuurcontroleWizard.html";
import "./FactuurcontroleWizard.scss";

const namespace = "wrp/components/factuur/FactuurcontroleWizard";

// names of the fields used > this changes when you change the names/keys of the fields
const FIELD_APPROVE_REJECT = "keuze_goed_of_afkeuren";
const FIELD_VALIDATED_VALUE = "gekeurde_waarde";
const FIELD_CATEGORY = "keuze_categorie";
const FIELD_REJECT_REASON = "afwijsreden_tekst";
const FIELD_REJECT_OTHER = "andere_afwijsreden";

// order/indices of components > this changes when the fields change order or fields are added/deleted
const listComponents = {
  FIELD_APPROVE_REJECT: 0,
  FIELD_CATEGORY: 2,
  FIELD_REJECT_OTHER: 4,
};

// names of the computed expressions used > this changes when you change the names/keys of the computed expressions
const CE_APPROVE = "goedkeuren";
const CE_REJECT = "afkeuren";
const CE_REJECT_OTHER = "andere_afwijsreden";
const CE_TO_VALIDATE = "te_keuren_voor_factuur";

angular
  .module(namespace, [])
  .factory("wrpFactuurcontroleWizard", function ($timeout, $q, dialogService) {
    "ngInject";

    return {
      template: template,
      link: linkFactuurcontroleWizard,
    };

    function asPromise(f) {
      let q = $q.defer();
      f(function (err, result) {
        if (err) {
          q.reject(err);
          return;
        }
        q.resolve(result);
      });
      return q.promise;
    }

    function linkFactuurcontroleWizard(scope, _, manipulator) {
      // Initialisation
      scope.connectComponentsToElementManipulators = (
        elementManipulator,
        component
      ) => {
        // Connect components to manipulators, this is necessary for correct scrolling behaviour in the sidebar
        elementManipulator.component = component;
        scope.goedOfAfkeurenManipulator(elementManipulator).component =
          component.listElementComponent.components[0];
        scope.bedragManipulator(elementManipulator).component =
          component.listElementComponent.components[1];
        scope.categorieManipulator(elementManipulator).component =
          component.listElementComponent.components[2];
        scope.afwijsredenManipulator(elementManipulator).component =
          component.listElementComponent.components[3];
        scope.andereAfwijsredenManipulator(elementManipulator).component =
          component.listElementComponent.components[4];
      };

      // Sub manipulators and components
      scope.goedOfAfkeurenManipulator = (elementManipulator) => {
        return elementManipulator.propertyManipulators[FIELD_APPROVE_REJECT];
      };
      scope.goedOfAfkeurenOptions = (component) => {
        return component.listElementComponent.components[
          listComponents.FIELD_APPROVE_REJECT
        ].options;
      };
      scope.bedragManipulator = (elementManipulator) => {
        return elementManipulator.propertyManipulators[FIELD_VALIDATED_VALUE];
      };
      scope.categorieManipulator = (elementManipulator) => {
        return elementManipulator.propertyManipulators[FIELD_CATEGORY];
      };
      scope.categorieOptions = (component, elementManipulator) => {
        return filter(
          component.listElementComponent.components[
            listComponents.FIELD_CATEGORY
          ].options,
          (option) => {
            return scope
              .categorieManipulator(elementManipulator)
              .optionManipulators[option.name].isActive();
          }
        );
      };
      scope.afwijsredenManipulator = (elementManipulator) => {
        return elementManipulator.propertyManipulators[FIELD_REJECT_REASON];
      };
      scope.andereAfwijsredenManipulator = (elementManipulator) => {
        return elementManipulator.propertyManipulators[FIELD_REJECT_OTHER];
      };
      scope.andereAfwijsredenComponent = (component) => {
        return component.listElementComponent.components[
          listComponents.FIELD_REJECT_OTHER
        ];
      };

      // Icons
      scope.iconsForCategory = iconsForCategory;

      // Should show certain parts of the wizard
      scope.shouldShowCategorieen = (elementManipulator) => {
        return elementManipulator.computedExpressions[CE_APPROVE];
      };
      scope.shouldShowAfwijsreden = (elementManipulator) => {
        return elementManipulator.computedExpressions[CE_REJECT];
      };
      scope.shouldShowAndereAfwijsreden = (elementManipulator) => {
        return elementManipulator.computedExpressions[CE_REJECT_OTHER];
      };

      // If a previous line was rejected, copy the rejection reason
      scope.takePreviousRejectionReasonIfPossible = function (
        elementManipulator
      ) {
        // initialise the reference list
        scope.initAfwijsredenReferencelist(elementManipulator);

        // if this line already has a rejection reason, leave it as it is
        let selectedOption =
          scope.afwijsredenManipulator(elementManipulator).referenceListQuery;
        if (selectedOption) return;

        // if not, go see what the previous rejection rule was and use that as this rejection rule
        // start at the index of the current line (or if not found, the last line)
        let indexOfCurrentElement = elementManipulator.path[5];
        if (!Number.isInteger(indexOfCurrentElement))
          indexOfCurrentElement = manipulator.nestedManipulators.length - 1;
        for (let i = indexOfCurrentElement; i >= 0; i--) {
          // index stored in path starts at 1, not 0
          let previousReason = scope.afwijsredenManipulator(
            manipulator.nestedManipulators[i]
          ).referenceListQuery;
          if (previousReason) {
            scope
              .afwijsredenManipulator(elementManipulator)
              .state.setMatch(previousReason);
            return; // stop looking for rejection reasons, we found one (the closest that is above this line)
          }
        }
        // if no rejection rule is found above the current line, leave the field empty
      };

      // Add remaining amount
      scope.remainingAmount = function () {
        return manipulator.computedExpressions[CE_TO_VALIDATE];
      };
      scope.addItemWithRemainingAmount = function () {
        manipulator.addElement();
        let newElement =
          manipulator.elementManipulators[
            manipulator.elementManipulators.length - 1
          ];
        let newElementManipulator = scope.bedragManipulator(newElement);
        newElementManipulator.value = scope.remainingAmount();
      };

      // Referencelist controls for rejection rules
      scope.initAfwijsredenReferencelist = (elementManipulator) => {
        $timeout(function () {
          scope.autocompleteAfwijsreden("", elementManipulator, function () {});
        });
        if (
          !scope
            .afwijsredenManipulator(elementManipulator)
            .hasOwnProperty("referenceListQuery")
        ) {
          Object.defineProperty(
            scope.afwijsredenManipulator(elementManipulator),
            "referenceListQuery",
            {
              get: function () {
                return scope
                  .afwijsredenManipulator(elementManipulator)
                  .getQuery();
              },
              set: function (v) {
                scope.afwijsredenManipulator(elementManipulator).inputQuery(v);
              },
            }
          );
        }
      };
      scope.autocompleteAfwijsreden = function (query, elementManipulator) {
        return asPromise(function (cb) {
          return scope
            .afwijsredenManipulator(elementManipulator)
            .state.autocompleteFor(query, cb);
        });
      };
      scope.onFocusAfwijsreden = function (event) {
        $timeout(function () {
          // open dropdown
          // FIXME: works the first time you focus on the element, but not a subsequent focus
          angular.element(event.target).triggerHandler("input");
          angular.element(event.target).triggerHandler("change"); // for IE
        });
      };
      scope.renderMatchAfwijsreden = (elementManipulator, match) =>
        scope
          .afwijsredenManipulator(elementManipulator)
          .state.renderMatch(match);
      scope.selectMatchAfwijsreden = (elementManipulator, referenceListQuery) =>
        scope
          .afwijsredenManipulator(elementManipulator)
          .state.setMatch(referenceListQuery);

      // List controls
      scope.addItem = function () {
        manipulator.addElement();
      };
      scope.deleteItem = function (i, elementManipulator) {
        const title = "<span translate>Delete invoice value</span>";
        const content = `<div>
                            <span translate>Are you sure you want to delete?</span>
                            <div class="text-right">
                                <button ng-click='$dialog.data.deleteLine($dialog.data.element); $dialog.closeThisDialog()' translate>Yes</button>
                                <button class="is-inverted" ng-click='$dialog.closeThisDialog()' translate>No</button>
                            </div>
                          </div>`;
        const data = {
          element: elementManipulator,
          deleteLine: (elementManipulator) =>
            elementManipulator.deleteElement(),
        };
        dialogService.openDialog(title, content, data);
      };
      scope.hasItems = function () {
        return (
          manipulator.nestedManipulators &&
          manipulator.nestedManipulators.length > 0
        );
      };
    }
  });

export default namespace;
