export default {
  binnenrenovatie: "wrp-icon-binnenrenovatie",
  buitenschrijnwerkGlas: "wrp-icon-buitenschrijnwerk",
  glasZonderVentilatie: "icon-vent",
  dakrenovatie: "wrp-icon-dakrenovatie",
  gascondensatieketel: "wrp-icon-gascondensatieketel",
  muurrenovatie: "wrp-icon-muurrenovatie",
  technischeInstallaties: "wrp-icon-technische-installaties",
  vloerrenovatie: "wrp-icon-vloerrenovatie",
  warmtepomp: "wrp-icon-warmtepomp",
  warmtepompboiler: "wrp-icon-warmtepompboiler",
  zonneboiler: "wrp-icon-zonneboiler",
};
