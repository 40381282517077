import angular from "angular";
import get from "lodash/get";
import isArray from "lodash/isArray";
import isFunction from "lodash/isFunction";
import isUndefined from "lodash/isUndefined";
import join from "lodash/join";
import keys from "lodash/keys";
import map from "lodash/map";
import mapValues from "lodash/mapValues";
import pickBy from "lodash/pickBy";
import replace from "lodash/replace";
import takeRight from "lodash/takeRight";
import values from "lodash/values";

import iconsForCategory from "@skryv/bundle/customizations/constants/iconsForCategories";

import template from "./FactuurcontroleOverview.html";
import "./FactuurcontroleOverview.scss";

const namespace = "wrp/components/factuur/FactuurcontroleOverview";

// names of the fields used > this changes when you change the names/keys of the fields
const FIELD_INVOICE_DATE = "factuurdatum";
const FIELD_INVOICE_BTW_CONTRACTOR = "btwNummer";
const FIELD_INVOICE_TOTAL = "totaalExclBtw";
const FIELD_COMMENT = "opmerkingen_intern";
const FIELD_ISO = "betrekkingTotIsolatie";
const FIELD_ASB = "betrekkingTotAsbest";

// names of the computed expressions used > this changes when you change the names/keys of the computed expressions
const CE_REQUESTED_CATEGORIES = "aangevraagde_categorieen";
const CE_REQUESTED_CATEGORIES_OK = "aangevraagde_categorieen_technisch_ok";
const CEF_INVOICE_APPROVED = "bedrag_factuur_goedgekeurd_voor";
const CE_INVOICE_APPROVED = "goedgekeurd_voor_factuur";
const CE_INVOICE_REJECTED = "afgekeurd_voor_factuur";
const CE_INVOICE_STATUS = "status";
const CE_INVOICE_REJECTION_PCT = "afgekeurd_percentage_voor_factuur";
const CE_INVOICE_CATEGORY = "categorieen_factuur";

angular
  .module(namespace, [])
  .factory("wrpFactuurcontroleOverview", function (dialogService, $state) {
    "ngInject";

    return {
      template: template,
      link: linkFactuurcontroleOverview,
    };

    function linkFactuurcontroleOverview(scope, input, manipulator) {
      // General helpers
      const getFieldValue = (manipulator, fieldPath) => {
        const path = isArray(fieldPath)
          ? fieldPath.join(".propertyManipulators.").split(".")
          : [fieldPath];
        return get(manipulator, ["propertyManipulators", ...path, "value"]);
      };

      const getComputedExpressionValue = (
        manipulator,
        computedExpressionsName,
        ...args
      ) => {
        const expression = get(manipulator, [
          "computedExpressions",
          computedExpressionsName,
        ]);
        return isFunction(expression) ? expression(...args) : expression;
      };

      // Columns to use in the factuurcontrole table
      scope.columnsToShow = [
        {
          type: "datum",
          header: "Datum",
          label: "Factuurdatum",
          display: "date",
          default: "-",
          width: "20%",
          getValue: (elementManipulator) =>
            getFieldValue(elementManipulator, [
              "factuurInhoud",
              FIELD_INVOICE_DATE,
            ]),
        },
        {
          type: "aannemer",
          header: "Aannemer",
          label: "BTW Aannemer",
          default: "-",
          width: "14%",
          getValue: (elementManipulator) =>
            btwModifier(
              getFieldValue(elementManipulator, [
                "factuurInhoud",
                FIELD_INVOICE_BTW_CONTRACTOR,
              ])
            ),
        },
        {
          type: "totaal",
          header: "Totaal",
          label: "Factuurtotaal",
          display: "currency",
          default: "-",
          width: "23%",
          getValue: (elementManipulator) =>
            invoiceTotalFormatter(
              getFieldValue(elementManipulator, [
                "factuurInhoud",
                FIELD_INVOICE_TOTAL,
              ])
            ),
        },
        {
          type: "cat",
          header: "Categ.",
          label: "Categorie van de factuur",
          display: "multi-icons",
          default: "-",
          width: "13%",
          getValue: (elementManipulator) =>
            getCategoryIcons(
              getComputedExpressionValue(
                elementManipulator,
                CE_INVOICE_CATEGORY
              )
            ),
        },
        {
          type: "status",
          header: "Status",
          label:
            "Status van de factuur: volledig klaar, voorlopig on hold, mee bezig of nog niet gestart",
          display: "single-icon",
          default: "-",
          width: "9%",
          getValue: (elementManipulator) =>
            getStatusIcons(
              getComputedExpressionValue(elementManipulator, CE_INVOICE_STATUS)
            ),
        },
        {
          type: "isolatie",
          header: "I",
          label:
            "Deze factuur heeft betrekking op isolatiewerken / het plaatsen van hoogrendementsglas",
          display: "single-icon",
          default: "-",
          width: "5%",
          getValue: (elementManipulator) =>
            getFieldValue(elementManipulator, ["factuurInhoud", FIELD_ISO])
              ? "skr-icon-check4"
              : undefined,
        },
        {
          type: "asbest",
          header: "A",
          label: "Deze factuur heeft betrekking op asbestverwijdering",
          display: "single-icon",
          default: "-",
          width: "5%",
          getValue: (elementManipulator) =>
            getFieldValue(elementManipulator, ["factuurInhoud", FIELD_ASB])
              ? "skr-icon-check4"
              : undefined,
        },
        {
          type: "afgekeurd",
          headerIcon: "skr-icon-thumbs-o-down",
          label: "Percentage van het factuurtotaal dat werd afgekeurd",
          display: "percentage",
          default: "-",
          width: "5%",
          getValue: (elementManipulator) =>
            getComputedExpressionValue(
              elementManipulator,
              CE_INVOICE_REJECTION_PCT
            ),
        },
        {
          type: "comment",
          headerIcon: "skr-icon-comment-o",
          label: "Is er een verwerkingsopmerking aanwezig?",
          display: "single-icon",
          default: "-",
          width: "5%",
          getValue: (elementManipulator) =>
            getFieldValue(elementManipulator, ["factuurInhoud", FIELD_COMMENT])
              ? "comment-icon skr-icon-comment-o"
              : undefined,
        },
      ];

      let btwModifier = (btw) => {
        // removes all non-digits and return only the last 4 digits
        if (!btw) return undefined;
        return join(takeRight(replace(btw, /\D/g, ""), 4), "");
      };

      let invoiceTotalFormatter = (factuurtotaal) => {
        // formats the invoice total with thin spaces and two digits after the comma
        if (!factuurtotaal) return undefined;
        return factuurtotaal
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, "\u2009");
      };

      let getCategoryIcons = (options) => {
        let iconForEachCategory = mapValues(options, (value, key) =>
          value ? `category-icon ${iconsForCategory[key]}` : false
        );
        return values(pickBy(iconForEachCategory, (value) => !!value)); // convert to array with icon for each selected category
      };

      let getStatusIcons = (selectedStatus) => {
        let statusIcons = {
          ongeldig: "skr-icon-cancel3 error",
          klaar: "skr-icon-check4 success",
          on_hold: "skr-icon-thin-circle-pauze",
          lopende: "skr-icon-thin-circle-half",
          niet_gestart: "skr-icon-thin-circle-empty",
        };
        return statusIcons[selectedStatus];
      };

      // Extra information on hover: categories
      const requestedCategoriesKeys = keys(
        pickBy(
          getComputedExpressionValue(manipulator, CE_REQUESTED_CATEGORIES),
          (category) => category
        )
      );

      scope.categoryInformation = [
        {
          type: "goedgekeurd",
          label: "Goedgekeurd factuurbedrag",
          icon: "skr-icon-thumbs-o-up",
          status: "aangenomen",
          getValue: (elementManipulator) =>
            getComputedExpressionValue(elementManipulator, CE_INVOICE_APPROVED),
          getPercentage: (elementManipulator) =>
            (getComputedExpressionValue(
              elementManipulator,
              CE_INVOICE_APPROVED
            ) /
              getFieldValue(elementManipulator, FIELD_INVOICE_TOTAL)) *
            100,
        },
        {
          type: "afgekeurd",
          label: "Afgekeurd factuurbedrag",
          icon: "skr-icon-thumbs-o-down",
          status: "afgewezen",
          getValue: (elementManipulator) =>
            getComputedExpressionValue(elementManipulator, CE_INVOICE_REJECTED),
          getPercentage: (elementManipulator) =>
            (getComputedExpressionValue(
              elementManipulator,
              CE_INVOICE_REJECTED
            ) /
              getFieldValue(elementManipulator, FIELD_INVOICE_TOTAL)) *
            100,
        },
        ...map(requestedCategoriesKeys, (categoryKey) => ({
          type: categoryKey,
          label: "Goedgekeurd voor",
          icon: iconsForCategory[categoryKey],
          status: getComputedExpressionValue(
            manipulator,
            CE_REQUESTED_CATEGORIES_OK
          )[categoryKey]
            ? "technisch_ok"
            : "technisch_afgekeurd",
          getValue: (elementManipulator) => {
            return getComputedExpressionValue(
              elementManipulator,
              CEF_INVOICE_APPROVED,
              categoryKey
            );
          },
        })),
      ];

      // Sorting
      scope.sortOptions = input.inputOptions.sortingOptions;

      scope.sort = (option) => {
        manipulator.sortElements((elementManipulator) =>
          get(elementManipulator, option.sortValuePath)
        );
        scope.sorting = false;
      };

      scope.sorting = false; // initialise

      scope.toggleSorting = () => {
        scope.sorting = !scope.sorting;
      };

      // Go to subitem
      scope.stateInfo = (indexId) => {
        const { documentId, taskId } = $state.params;

        if (!isUndefined(documentId)) {
          return {
            state: "subdocument",
            stateParams: {
              documentId: documentId,
              itemId: indexId + 1,
            },
          };
        }
        return {
          state: "subtask",
          stateParams: {
            taskId: taskId,
            itemId: indexId + 1,
          },
        };
      };

      // List controls
      scope.addItem = function () {
        manipulator.addElement();
      };
      scope.deleteItem = function (i, elementManipulator) {
        const title = "<span translate>Invoice deletion</span>";
        const content = `<div>
                            <span translate>Are you sure you want to delete?</span>
                            <div class="text-right">
                                <button ng-click='$dialog.data.deleteInvoice($dialog.data.element); $dialog.closeThisDialog()' translate>Yes</button>
                                <button class="is-inverted" ng-click='$dialog.closeThisDialog()' translate>No</button>
                            </div>
                          </div>`;
        const data = {
          element: elementManipulator,
          deleteInvoice: (elementManipulator) =>
            elementManipulator.deleteElement(),
        };
        dialogService.openDialog(title, content, data);
      };
      scope.hasItems = function () {
        return (
          manipulator.nestedManipulators &&
          manipulator.nestedManipulators.length > 0
        );
      };
    }
  });

export default namespace;
