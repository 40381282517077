import angular from "angular";

import template from "./VersionDashboard.html";
import { fetchDossierTypeVersions } from "../../../store/actions";

const namespace = "@skryv/core-ng1/components/dashboard/VersionDashboard";

angular.module(namespace, []).component("wrpVersionsDashboard", {
  template,
  controller: function ($ngRedux) {
    const disconnect = $ngRedux.connect(mapStateToThis, {
      fetchDossierTypeVersions,
    })(this);

    this.$onDestroy = disconnect;

    this.$onInit = () => {
      this.fetchDossierTypeVersions().then((result) => {
        this.versions = result.api.response.data;
      });

      this.initialized = true;
    };

    function mapStateToThis(state) {
      return state;
    }
  },
});

export { template };
export default namespace;
