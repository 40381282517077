import capitalize from "lodash/capitalize";
import filter from "lodash/filter";
import get from "lodash/get";
import isArray from "lodash/isArray";
import includes from "lodash/includes";
import join from "lodash/join";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import some from "lodash/some";
import moment from "moment";

export function formatAddress(address) {
  if (!address) return;
  if (
    !(
      get(address, "street") &&
      get(address, "housenumber") &&
      get(address, "zipcode") &&
      get(address, "municipality")
    )
  )
    return address;

  if (get(address, "boxnumber")) {
    return (
      capitalizeAllWords(get(address, "street")) +
      " " +
      get(address, "housenumber") +
      ", bus " +
      get(address, "boxnumber") +
      "\n" +
      get(address, "zipcode") +
      " " +
      capitalizeAllWords(get(address, "municipality"))
    );
  } else {
    return (
      capitalizeAllWords(get(address, "street")) +
      " " +
      get(address, "housenumber") +
      "\n" +
      get(address, "zipcode") +
      " " +
      capitalizeAllWords(get(address, "municipality"))
    );
  }
}

export function capitalizeAllWords(string) {
  return string
    .split(" ")
    .map((word) => capitalize(word))
    .join(" ");
}

export function formatDate(date) {
  if (!date) return;
  return moment(date, "YYYY-MM-DD").format("DD/MM/Y");
}

export function formatIBAN(iban) {
  if (!iban) return;

  // Remove any existing spaces or non-alphanumeric characters
  iban = iban.replace(/[^a-zA-Z0-9]/g, "");

  // Insert a space every four characters
  const formattedIBAN = iban.match(/.{1,4}/g).join(" ");

  return formattedIBAN;
}

export function formatCategories(categories) {
  if (!categories) return;

  return join(
    map(categories, (category) => capitalize(get(category, ["nl"]))),
    "\n"
  );
}

export function getDocument(definitionKey, dossierDetails) {
  if (!dossierDetails) return;
  return get(
    orderBy(
      filter(get(dossierDetails, "document"), {
        definition: { key: definitionKey },
      }),
      ["updatedAt"],
      ["desc"]
    ),
    0,
    undefined
  );
}

export function getTask(definitionKey, dossierDetails) {
  if (!dossierDetails) return;
  return get(
    orderBy(
      filter(get(dossierDetails, "task"), {
        taskDefinitionKey: definitionKey,
      }),
      ["updatedAt"],
      ["desc"]
    ),
    0,
    undefined
  );
}

export function getField(
  document,
  definitionKey,
  fieldPath,
  fallbackValue = "- niet ingevuld -"
) {
  fieldPath = isArray(fieldPath) ? fieldPath : [fieldPath];
  return get(document, [definitionKey, ...fieldPath], fallbackValue);
}

export function getActiveTasks(dossierDetails) {
  if (!dossierDetails) return [];

  return orderBy(
    filter(get(dossierDetails, "task"), {
      active: true,
    }),
    ["created"],
    ["desc"]
  );
}

export function getHistoricTasks(dossierDetails) {
  if (!dossierDetails) return [];

  return orderBy(
    filter(get(dossierDetails, "task"), {
      active: false,
    }),
    ["ended"],
    ["desc"]
  );
}

export function getProcessInformation(dossierDetails, $state) {
  if (!dossierDetails) return;
  return map(getHistoricTasks(dossierDetails), (task) => {
    return {
      id: task.id,
      name: task.name,
      task: task,
      status: "finished",
      action: (task) => $state.go("task", { taskId: task.id }),
      hasAction: true,
      canExecute: true,
      actionLabel: "Open",
    };
  });
}

export function goToDossierPage(dossierId, pageName, $state) {
  $state.go(pageName, { dossierId }, { reload: false });
}

export function goToDossierPageInNewTab(dossierId, pageName, $state) {
  const url = $state.href(pageName, { dossierId });
  const newTab = window.open(url, "_blank");
  if (newTab) {
    newTab.focus();
  } else {
    // Handle if the browser's popup blocker prevents opening a new tab
    // Open in same tab
    $state.go(pageName, { dossierId }, { reload: false });
  }
}

export function goToDocumentPage(definitionKey, dossierDetails, $state) {
  $state.go("document", {
    documentId: get(getDocument(definitionKey, dossierDetails), "id"),
  });
}

export function goToTaskPage(definitionKey, dossierDetails, $state) {
  $state.go("task", {
    taskId: get(getTask(definitionKey, dossierDetails), "id"),
  });
}

export function hasSomeOfMilestones(
  allMilestones,
  keysOfInterestingMilestones
) {
  keysOfInterestingMilestones = isArray(keysOfInterestingMilestones)
    ? keysOfInterestingMilestones
    : [keysOfInterestingMilestones];
  const allMilestoneKeys = map(allMilestones, "key");
  return some(keysOfInterestingMilestones, (milestone) =>
    includes(allMilestoneKeys, milestone)
  );
}

export function getLatestMilestone(milestones) {
  if (milestones) return milestones[0];
  return null;
}

export function getTimestampOfMilestone(allMilestones, key) {
  for (let i = 0; i < allMilestones.length; i++) {
    if (allMilestones[i].key === key) {
      return allMilestones[i].timestamp;
    }
  }
  return null;
}
