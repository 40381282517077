/**
 * IMPORTANT: You should not have to modify this file.
 * Any component javascript file in a subfolder will be automatically imported,
 * and added as a dependency, assuming it has the module name as default export
 * (or as named export `namespace`).
 **/

import collectModules from "@skryv/core-ng1/util/collect-modules";

const ctx = require.context(".", true, /^(?!\.\/index)(?!.+\.test\.js).+\.js$/);
const namespace = "wrp/components";

collectModules(ctx, namespace);

export default namespace;
